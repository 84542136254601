import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function About() {
  return (
    <>
      <Typography variant="h5">About Us!</Typography>

      <Typography variant="body1" component="span">
        <p>
          This tool was developed by <Link href="https://jemurai.com">Jemurai</Link>,
          the makers of <Link href="https://securityprogram.io">securityprogram.io</Link>.
          It is intended to be a handy utility to provoke good thinking.
        </p>

        <p>
          If you want to talk more about pragmatic security budgeting grab your estimates and reach out to
          discuss!! We have more detailed budget templates we can share with you.
        </p>
      </Typography>

      You can catch us at:
      <dl>
        <dt>Email</dt>
        <dd><Link href="mailto:info@securityprogram.io">info@securityprogram.io</Link></dd>

        <dt>Twitter</dt>
        <dd><Link href="https://twitter.com/securityprog_io">@securityprog_io</Link></dd>

        <dt>LinkedIn</dt>
        <dd><Link href="https://www.linkedin.com/company/securityprogram/">SecurityProgram.io</Link></dd>
      </dl>
    </>
  );
}

export default About;

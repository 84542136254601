import React from 'react';
import Typography from '@mui/material/Typography';
import CurrencyField from './CurrencyField';

function SecurityBudget(props) {
  const { handleChange, values } = props;

  return (
    <>
      <Typography variant="h5">Estimate Based on Past Security Budgets</Typography>

      <Typography variant="body1">
        A common way to create a security budget is to add some % to last year's. Of course, this doesn't
        always capture what is really needed.
      </Typography>

      <CurrencyField
        autoFocus
        label="Previous Security Budget"
        onChange={handleChange('past_security_budget')}
        value={values.past_security_budget}
      />
    </>
  );
}

export default SecurityBudget;

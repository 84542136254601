import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const basicTheme = createTheme({
    palette: {
      primary: {
        main: '#db4e1d',
      },
      secondary: {
        main: '#2f2f68',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#e6e6e6',
      },
    },
  },
);

const theme = createTheme(basicTheme, {
  components: {
    MuiAppBar: {
      defaultProps: {
        position: 'static',
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
          '& div:first-of-type > span:first-of-type': {
            backgroundColor: basicTheme.palette.primary.main,
            height: '100%',
            display: 'block',
            paddingBottom: 0,
          },
          '& #logo': {
            height: '3em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
          },
        },

      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: basicTheme.spacing(2),
          margin: basicTheme.spacing(2),
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: basicTheme.spacing(1),
          width: '100%',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          '& th': {
            fontWeight: 'bold',
            backgroundColor: basicTheme.palette.secondary.light,
            color: basicTheme.palette.secondary.contrastText,
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          '& tr > th[scope="col"],td[scope="col"]': {
            textAlign: 'center',
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});


export default theme;

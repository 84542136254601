import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import NumberFormat from 'react-number-format';

function handleValueChange(parentOnChange) {
  return function (values, source) {
    const e = source.event;

    e.target.value = values.floatValue;
    parentOnChange && parentOnChange(e);
  };
}

function CurrencyField({ autoFocus, label, onChange, value }) {
  return (
    <FormControl>
      <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
      <NumberFormat
        allowLeadingZeros={false}
        allowNegative={false}
        autoFocus={autoFocus}
        customInput={OutlinedInput}
        prefix="$"
        thousandSeparator={true}
        id="outlined-adornment-amount"
        value={value}
        onValueChange={handleValueChange(onChange)}
        label={label}
      />
    </FormControl>
  );
}

export default CurrencyField;

import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CurrencyField from './CurrencyField';
import CheckboxField from './CheckboxField';

function SimpleEstimate(props) {
  const { handleChange, values } = props;

  return (
    <>
      <Typography variant="h5">Estimate Based on More Factors</Typography>

      <Typography variant="body1" component="span">
        <p>If we combine a number of factors, we can get more detailed.</p>
        <p>This page collects a number of common factors.</p>
      </Typography>

      <CurrencyField
        autoFocus
        label="Monthly Cloud Spend ($)"
        onChange={handleChange('se_cloudspend')}
        value={values.se_cloudspend}
      />

      <CurrencyField
        label="Annual Revenue"
        onChange={handleChange('se_revenue')}
        value={values.se_revenue}
      />

      <TextField
        label="Number of Engineers"
        onChange={handleChange('se_engineers')}
        value={values.se_engineers}
        inputProps={{
          type: 'numeric',
          min: 0,
        }}
      />


      <TextField
        label="Number of Employees"
        onChange={handleChange('se_employees')}
        value={values.se_employees}
        inputProps={{
          type: 'numeric',
        }}
      />

      <TextField
        label="Number of Products"
        onChange={handleChange('se_products')}
        value={values.se_products}
        inputProps={{
          type: 'numeric',
        }}
      />

      <FormGroup>
        <FormLabel component="legend">Additional Info</FormLabel>
        <CheckboxField
          checked={values.se_audit}
          label="Expect to Conduct Audit"
          onChange={handleChange('se_audit')}
        />

        <CheckboxField
          checked={values.se_regulated}
          label="Are you highly regulated?  (PHI, PCI, ... )"
          onChange={handleChange('se_regulated')}
        />

        <CheckboxField
          checked={values.se_priority}
          label="Is security a priority for you?"
          onChange={handleChange('se_priority')}
        />
      </FormGroup>
    </>
  );
}

export default SimpleEstimate;

import React from 'react';

import {
  midComplexityBudget,
  simplePercentOfITBudget,
  simplePercentOfRevenue,
  simplePercentOfSecurityBudget,
} from '../securityBudgetCalcs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const TableCellHeading = (props) => <TableCell component="th" scope="row" {...props} name={TableCellHeading.name} />;


function Summary({ values }) {
  values.itb_security = values.itb_budget * 0.10;
  values.rev_security = values.rev_revenue * 0.03;
  values.psb_security = values.past_security_budget * 1.05;
  values.se_security = 0;

  return (
    <>
      <Typography variant="h5">Summary of Estimates</Typography>

      <Typography variant="body1">
        We show the estimates together for a reason: so that you can think about why they are different.
        None of these are production ready, but they may triangulate to something interesting.
      </Typography>

      <br />
      <TableContainer width="100px">
        <Table sx={{ width: 'auto', minWidth: '20em' }}>
          <TableHead>
            <TableRow>
              <TableCellHeading scope="col">Calculation Method</TableCellHeading>
              <TableCellHeading scope="col">Budget</TableCellHeading>
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& tr > th,td': { textAlign: 'right' } }}>
            <TableRow>
              <TableCellHeading>IT Budget</TableCellHeading>
              <TableCell>{simplePercentOfITBudget(values.itb_budget)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCellHeading>Revenue</TableCellHeading>
              <TableCell>{simplePercentOfRevenue(values.rev_revenue)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCellHeading>Past Security</TableCellHeading>
              <TableCell>{simplePercentOfSecurityBudget(values.past_security_budget)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCellHeading>Estimator</TableCellHeading>
              <TableCell>
                {midComplexityBudget(values.se_cloudspend,
                  values.se_engineers, values.se_employees, values.se_revenue,
                  values.se_products, values.se_audit, values.se_regulated,
                  values.se_priority)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}

export default Summary;

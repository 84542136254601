import React from 'react';
import Typography from '@mui/material/Typography';
import CurrencyField from './CurrencyField';

class ITBudget extends React.Component {
  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { handleChange, values } = this.props;

    return (
      <>
        <Typography variant="h5">Estimate Based on IT Budget</Typography>

        <Typography variant="body1">
          A common way to estimate an appropriate security budget is to estimate as a percentage of your IT budget
        </Typography>

        <CurrencyField autoFocus value={values.itb_budget} label="IT Budget" onChange={handleChange('itb_budget')} />
      </>
    );
  }
}

export default ITBudget;

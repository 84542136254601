import React from 'react';
import logo from './SPIO-Logo.png';
import { AppBar, Grid, Typography } from '@mui/material';
import CalculatorWizard from './components/CalculatorWizard';


function App() {
  return (
    <>
      <AppBar>
        <Grid container justifyContent="flex-start" alignItems="stretch" direction="row" spacing={2}>
          <Grid item xs={1.5}>
            <span />
          </Grid>
          <Grid item xs="auto">
            <Typography variant="h6">
              <a
                href="https://securityprogram.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img id="logo" src={logo} alt="logo" style={{ verticalAlign: 'middle' }} /></a>
              &nbsp;|&nbsp;Security Budget Estimation Tool</Typography>
          </Grid>
        </Grid>
      </AppBar>

      <CalculatorWizard />
    </>
  );
}

export default App;

import React from 'react';
import Typography from '@mui/material/Typography';
import CurrencyField from './CurrencyField';

class Revenue extends React.Component {
  render() {
    const { handleChange, values } = this.props;

    return (
      <>
        <Typography variant="h5">Estimate Based on Revenue</Typography>
        <span>
            <p>Sometimes we're spending a lot on IT but don't have high revenue.</p>
            <p>So revenue is also a factor when thinking about security budgets.</p>
        </span>

        <CurrencyField
          autoFocus
          label="Annual Revenue"
          onChange={handleChange('rev_revenue')}
          value={values.rev_revenue}
        />
      </>
    );
  }
}

export default Revenue;

import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Intro from './Intro';
import ITBudget from './ITBudget';
import Revenue from './Revenue';
import SecurityBudget from './SecurityBudget';
import SimpleEstimate from './SimpleEstimate';
import About from './About';
import Summary from './Summary';

const steps = [
  {
    label: 'Intro',
    component: Intro,
  },

  {
    label: 'IT Budget',
    component: ITBudget,
  },

  {
    label: 'Revenue',
    component: Revenue,
  },

  {
    label: 'Security Budget',
    component: SecurityBudget,
  },

  {
    label: 'Estimate',
    component: SimpleEstimate,
  },

  {
    label: 'About',
    component: About,
  },

  {
    label: 'Summary',
    component: Summary,
  },
];

class CalculatorWizard extends React.Component {
  state = {
    step: 0,
    itb_budget: null,
    itb_security: null,
    rev_revenue: null,
    rev_security: null,
    past_security_budget: null,
    psb_security: null,
    se_cloudspend: 15000,
    se_engineers: 40,
    se_employees: 100,
    se_revenue: 5000000,
    se_products: 1,
    se_audit: false,
    se_regulated: false,
    se_priority: false,
    se_security: null,
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.baseState = this.state;
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  startOver = () => {
    this.setState(this.baseState);
  };

  handleChange = (input) => event => {
    if (event.target.type === 'checkbox') {
      this.setState({ [input]: event.target.checked });
    } else {
      this.setState({ [input]: event.target.value });
    }
  };

  render() {
    return (
      <Card>
        <CardContent>
          <Stepper activeStep={this.state.step}>
            {steps.map((step, index) =>
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>,
            )}
          </Stepper>
          <hr />
          {React.createElement(steps[this.state.step].component, {
            values: this.state,
            handleChange: this.handleChange,
          })}
        </CardContent>
        <CardActions>
          {this.state.step === 0 ?
            <Button onClick={this.nextStep}>Get Started</Button>
            : this.state.step === steps.length - 2 ?
              <Button onClick={this.nextStep}>Get Results</Button>
              : this.state.step === steps.length - 1 ?
                <Button onClick={this.startOver}>Start Over</Button>
                :
                <>
                  <Button onClick={this.prevStep} color="secondary">Back</Button>
                  <Button onClick={this.nextStep}>Continue</Button>
                </>
          }
        </CardActions>
      </Card>
    );
  }
}

export default CalculatorWizard;

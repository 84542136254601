
/**
 * Formats a budget number as US Dollars.
 * 
 * @param {number} budget
 */
 function formatAsDollars(budget: number) {
    let formattedBudget = budget.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });
    return formattedBudget;
  }

/**
 * Calculate a % of Budget and format as dollars.
 * 
 * @param {number} budget
 * @param {number} pct
 */

function simplePercentOfBudget(budget: number, pct: number) {
    let securityBudget : number = pct/100 * budget    
    return securityBudget
  }

/**
 * Simple % of IT Budget
 * 
 * @param {number} budget
 */
 function simplePercentOfITBudget(budget: number) {
    let percent : number = 12
    return formatAsDollars(simplePercentOfBudget(budget, percent))
  }

/**
 * Simple % of Revenue
 * 
 * @param {number} budget
 */
 function simplePercentOfRevenue(budget: number) {
    let percent : number = 10
    return formatAsDollars(simplePercentOfBudget(budget, percent))
  }

/**
 * Simple % of Security Budget
 * 
 * @param {number} budget
 */
 function simplePercentOfSecurityBudget(budget: number) {
    let percent : number = 105
    return formatAsDollars(simplePercentOfBudget(budget, percent))
  }

/**
 * Simple % of Total Budget
 * 
 * @param {number} budget
 */
 function simplePercentOfTotalBudget(budget: number) {
    let percent : number = 2
    return formatAsDollars(simplePercentOfBudget(budget, percent))
  }

/**
 * Mid Complexity with Cloud, Engineers
 * 
 * @param {number} cloudspend
 * @param {number} engineers
 * @param {number} employees
 * @param {number} revenue
 * @param {number} products
 * @param {boolean} audit
 * @param {boolean} regulated
 * @param {boolean} priority
 */
 function midComplexityBudget(cloudspend: number, engineers: number, employees: number, revenue: number, 
                              products: number, audit: boolean, regulated: boolean, priority: boolean) {
    let budget : number = 0

    // Run Cloud Security Related Tools
    if (priority){
      budget += simplePercentOfBudget(12*cloudspend, 6) // Cloud security tools
    }else{
      budget += simplePercentOfBudget(12*cloudspend, 2) // Cloud security tools, but only the minimum.
    }

    // IT Related
    budget += employees * 12 * 10 // Laptops / XDR

    // People (Your security team)
    budget += Math.round(engineers / 100) * 150000  // 1 security per 100 engineers
    budget += Math.round(employees / 250) * 150000  // 1 security per 250 employees

    // Insurance
    if (regulated ){
      budget += 10000 + (revenue / 200) // Insurance
    } else {
      budget += 10000 // Insurance
    }

    // Doing a Penetration Test for any products
    if (priority){
      budget += 20000 * Math.floor(products)
    } else {
      budget += 10000 * Math.floor(products)
    }

    // Adding a Security Program if small (<$10M Rev)
    if ((revenue < 10000000) && (priority || audit || regulated )) {
      budget += 3000 * 12 // SPIO
    }

    if (revenue > 100000000){      // Maybe 100M is a good line in the sand for AppSec
      budget += 100000 * products  // AppSec Program (SAST/DAST/SCA)
      budget += 1000 * engineers   // Training
    }

    // Monitoring Estimate
    if (regulated || priority) {
        budget += 3000 * 12 // Outsourced SOC (security monitoring)
    }
    // SOC Audit Estimate
    if (audit || priority) {
        budget += 100000 //* (revenue / 2) // Rough cost of SOC 2 
    }

    return formatAsDollars(budget)
  }



//  export default simplePctITBudget;
export { simplePercentOfITBudget, simplePercentOfTotalBudget, simplePercentOfRevenue, simplePercentOfSecurityBudget, midComplexityBudget};

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function CheckboxField(props) {
  const { disabled, label, ...rest } = props;

  return (
    <FormControlLabel control={<Checkbox {...rest} />} label={label} />
  );
}

export default CheckboxField;
